export const HardwareModelIDToName = {
  '0': 'UNSET',
  '1': 'TLORA_V2',
  '2': 'TLORA_V1',
  '3': 'TLORA_V2_1_1P6',
  '4': 'TBEAM',
  '5': 'HELTEC_V2_0',
  '6': 'TBEAM_V0P7',
  '7': 'T_ECHO',
  '8': 'TLORA_V1_1P3',
  '9': 'RAK4631',
  '10': 'HELTEC_V2_1',
  '11': 'HELTEC_V1',
  '12': 'LILYGO_TBEAM_S3_CORE',
  '13': 'RAK11200',
  '14': 'NANO_G1',
  '15': 'TLORA_V2_1_1P8',
  '16': 'TLORA_T3_S3',
  '17': 'NANO_G1_EXPLORER',
  '18': 'NANO_G2_ULTRA',
  '19': 'LORA_TYPE',
  '20': 'WIPHONE',
  '21': 'WIO_WM1110',
  '22': 'RAK2560',
  '23': 'HELTEC_HRU_3601',
  '24': 'HELTEC_WIRELESS_BRIDGE',
  '25': 'STATION_G1',
  '26': 'RAK11310',
  '27': 'SENSELORA_RP2040',
  '28': 'SENSELORA_S3',
  '29': 'CANARYONE',
  '30': 'RP2040_LORA',
  '31': 'STATION_G2',
  '32': 'LORA_RELAY_V1',
  '33': 'NRF52840DK',
  '34': 'PPR',
  '35': 'GENIEBLOCKS',
  '36': 'NRF52_UNKNOWN',
  '37': 'PORTDUINO',
  '38': 'ANDROID_SIM',
  '39': 'DIY_V1',
  '40': 'NRF52840_PCA10059',
  '41': 'DR_DEV',
  '42': 'M5STACK',
  '43': 'HELTEC_V3',
  '44': 'HELTEC_WSL_V3',
  '45': 'BETAFPV_2400_TX',
  '46': 'BETAFPV_900_NANO_TX',
  '47': 'RPI_PICO',
  '48': 'HELTEC_WIRELESS_TRACKER',
  '49': 'HELTEC_WIRELESS_PAPER',
  '50': 'T_DECK',
  '51': 'T_WATCH_S3',
  '52': 'PICOMPUTER_S3',
  '53': 'HELTEC_HT62',
  '54': 'EBYTE_ESP32_S3',
  '55': 'ESP32_S3_PICO',
  '56': 'CHATTER_2',
  '57': 'HELTEC_WIRELESS_PAPER_V1_0',
  '58': 'HELTEC_WIRELESS_TRACKER_V1_0',
  '59': 'UNPHONE',
  '60': 'TD_LORAC',
  '61': 'CDEBYTE_EORA_S3',
  '62': 'TWC_MESH_V4',
  '63': 'NRF52_PROMICRO_DIY',
  '64': 'RADIOMASTER_900_BANDIT_NANO',
  '65': 'HELTEC_CAPSULE_SENSOR_V3',
  '66': 'HELTEC_VISION_MASTER_T190',
  '67': 'HELTEC_VISION_MASTER_E213',
  '68': 'HELTEC_VISION_MASTER_E290',
  '69': 'HELTEC_MESH_NODE_T114',
  '70': 'SENSECAP_INDICATOR',
  '71': 'TRACKER_T1000_E',
  '72': 'RAK3172',
  '73': 'WIO_E5',
  '74': 'RADIOMASTER_900_BANDIT',
  '75': 'ME25LS01_4Y10TD',
  '76': 'RP2040_FEATHER_RFM95',
  '77': 'M5STACK_COREBASIC',
  '78': 'M5STACK_CORE2',
  '79': 'RPI_PICO2',
  '80': 'M5STACK_CORES3',
  '81': 'SEEED_XIAO_S3',
  '82': 'MS24SF1',
  '83': 'TLORA_C6',
  '255': 'PRIVATE_HW',
} as Record<number, string>

export const HardwareModelNameToID = {
  UNSET: 0,
  TLORA_V2: 1,
  TLORA_V1: 2,
  TLORA_V2_1_1P6: 3,
  TBEAM: 4,
  HELTEC_V2_0: 5,
  TBEAM_V0P7: 6,
  T_ECHO: 7,
  TLORA_V1_1P3: 8,
  RAK4631: 9,
  HELTEC_V2_1: 10,
  HELTEC_V1: 11,
  LILYGO_TBEAM_S3_CORE: 12,
  RAK11200: 13,
  NANO_G1: 14,
  TLORA_V2_1_1P8: 15,
  TLORA_T3_S3: 16,
  NANO_G1_EXPLORER: 17,
  NANO_G2_ULTRA: 18,
  LORA_TYPE: 19,
  WIPHONE: 20,
  WIO_WM1110: 21,
  RAK2560: 22,
  HELTEC_HRU_3601: 23,
  HELTEC_WIRELESS_BRIDGE: 24,
  STATION_G1: 25,
  RAK11310: 26,
  SENSELORA_RP2040: 27,
  SENSELORA_S3: 28,
  CANARYONE: 29,
  RP2040_LORA: 30,
  STATION_G2: 31,
  LORA_RELAY_V1: 32,
  NRF52840DK: 33,
  PPR: 34,
  GENIEBLOCKS: 35,
  NRF52_UNKNOWN: 36,
  PORTDUINO: 37,
  ANDROID_SIM: 38,
  DIY_V1: 39,
  NRF52840_PCA10059: 40,
  DR_DEV: 41,
  M5STACK: 42,
  HELTEC_V3: 43,
  HELTEC_WSL_V3: 44,
  BETAFPV_2400_TX: 45,
  BETAFPV_900_NANO_TX: 46,
  RPI_PICO: 47,
  HELTEC_WIRELESS_TRACKER: 48,
  HELTEC_WIRELESS_PAPER: 49,
  T_DECK: 50,
  T_WATCH_S3: 51,
  PICOMPUTER_S3: 52,
  HELTEC_HT62: 53,
  EBYTE_ESP32_S3: 54,
  ESP32_S3_PICO: 55,
  CHATTER_2: 56,
  HELTEC_WIRELESS_PAPER_V1_0: 57,
  HELTEC_WIRELESS_TRACKER_V1_0: 58,
  UNPHONE: 59,
  TD_LORAC: 60,
  CDEBYTE_EORA_S3: 61,
  TWC_MESH_V4: 62,
  NRF52_PROMICRO_DIY: 63,
  RADIOMASTER_900_BANDIT_NANO: 64,
  HELTEC_CAPSULE_SENSOR_V3: 65,
  HELTEC_VISION_MASTER_T190: 66,
  HELTEC_VISION_MASTER_E213: 67,
  HELTEC_VISION_MASTER_E290: 68,
  HELTEC_MESH_NODE_T114: 69,
  SENSECAP_INDICATOR: 70,
  TRACKER_T1000_E: 71,
  RAK3172: 72,
  WIO_E5: 73,
  RADIOMASTER_900_BANDIT: 74,
  ME25LS01_4Y10TD: 75,
  RP2040_FEATHER_RFM95: 76,
  M5STACK_COREBASIC: 77,
  M5STACK_CORE2: 78,
  RPI_PICO2: 79,
  M5STACK_CORES3: 80,
  SEEED_XIAO_S3: 81,
  MS24SF1: 82,
  TLORA_C6: 83,
  PRIVATE_HW: 255,
} as Record<string, number>

export const NodeRoleIDToName = {
  '0': 'CLIENT',
  '1': 'CLIENT_MUTE',
  '2': 'ROUTER',
  '3': 'ROUTER_CLIENT',
  '4': 'REPEATER',
  '5': 'TRACKER',
  '6': 'SENSOR',
  '7': 'TAK',
  '8': 'CLIENT_HIDDEN',
  '9': 'LOST_AND_FOUND',
  '10': 'TAK_TRACKER',
} as Record<number, string>

export const NodeRoleNameToID = {
  CLIENT: 0,
  CLIENT_MUTE: 1,
  ROUTER: 2,
  ROUTER_CLIENT: 3,
  REPEATER: 4,
  TRACKER: 5,
  SENSOR: 6,
  TAK: 7,
  CLIENT_HIDDEN: 8,
  LOST_AND_FOUND: 9,
  TAK_TRACKER: 10,
} as Record<string, number>
